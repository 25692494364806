@import "functions.less";

@import "../../fonts/google-font-nunito-sans.less";

    /* Normalization */

        *{
            box-sizing: border-box;
        }

        html,body{
            font-family: "Nunito Sans", sans-serif;
            font-size:.px-to-rem(@global-font-size)[@px];
            padding:0;
            margin:0;    
            &.locked{
                overflow:hidden;
            }
        }

        button{
            cursor:pointer;
        }

        abbr{
          text-decoration:none !important;
        }

    /* Structure */

        .gfield_required{ display:none !important; }

        .allcaps{ text-transform: uppercase; }

        .column-gen(100);

        .container-micro{
          width:.px-to-rem(700)[@rem];
        }

        .container-small{
            width:.px-to-rem(1024)[@rem];  
        }

        .text-block-container{
            width:100%;
            padding:.px-to-rem(10)[@rem] .px-to-rem(20)[@rem];
        }

        .test-grid-container{
            width:.px-to-rem(1340)[@rem];
        }

        .container{
            width:.px-to-rem(1440)[@rem];
        }

        .purchase-container{
            width:.px-to-rem(1080)[@rem];
            padding:.px-to-rem(10)[@rem] .px-to-rem(20)[@rem];
        }
        .container,
        .container-small,
        .text-block-container,
        .test-grid-container,
        .purchase-container,
        .container-micro{
            max-width:100%;
            margin:auto;
            overflow:hidden;
            padding:10px;
        }

        .master-container{
            width:100%;
            padding:0 10px;
            margin:auto;
        }


    /* Elements */

        .small-title{
            color:@color-support-2-var;
            font-size:.px-to-rem(25)[@rem];
            font-weight:900;

            small{
                display:block;
                color:@color-palette-orange-var;
                font-size:.px-to-rem(13)[@rem];
                font-weight: 700;
                margin:0;
                padding:0;
            }
        }

        .global-cta{
            display:block;
            font-size:.px-to-rem(18)[@rem];
            padding: 0 .px-to-rem(25)[@rem];
            line-height:.px-to-rem(44)[@rem];
            border-radius:.px-to-rem(30)[@rem];
            border: 1px solid transparent;
            text-decoration:none;

            &.block-cta{
              margin: .px-to-rem(15)[@rem] auto;
              width: fit-content;
            }

            &.orange-cta{
                background-color:@color-palette-orange-var;
                border:1px solid white;
                color:white;
                font-weight:700;
                transition:.5s;
                &:hover{
                    background-color:white;
                    border:1px solid @color-palette-orange-var;
                    color:@color-palette-orange-var;
                }
            }

            &.white-cta {
              background-color: white;
              color: @color-palette-orange-var;
              border: 1px solid @color-palette-orange-var;
              font-weight: 700;
              transition: .5s;

              &:hover {
                background-color: @color-palette-orange-var;
                color: white;
                border: 1px solid white;
              }
            }

            &.teal-cta {
              background-color: @color-palette-teal-var;
              color: white;
              border: 1px solid @color-palette-teal-var;
              font-weight: 700;
              transition: .5s;

              &:hover {
                background-color: white;
                color: @color-palette-teal-var;
                border: 1px solid @color-palette-teal-var;
              }
            }

        }

        .hamburger-button{
            background-color:transparent;
            border:1px solid transparent;
            width:fit-content;
            margin:auto;
            span{
                display:block;
                width:.px-to-rem(35)[@rem];
                height:.px-to-rem(3)[@rem];
                margin: .px-to-rem(7)[@rem] 0;

                background-color:black;
            }
        }

        .cover-up-link{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index:5;
        }

        .floating-box{
            width:100%;
            background-color:white;
            padding:.px-to-rem(15)[@rem];
            box-shadow: 0px 0px 10px 0px fadeout(black,70%);
        }

        .double-content{
            display: flex;
            flex-flow: column nowrap;
            
            .iframe-container{ width: 100%; }
        }

        .author-box{
            border: 1px solid @color-support-2-var;
            display:flex;
            flex-flow:column nowrap;
            align-items: center;
            justify-content: center;
            max-width:calc(100% - .px-to-rem(20)[@rem]);
            margin:.px-to-rem(10)[@rem] auto;
    
            .content{
    
                padding:.px-to-rem(10)[@rem];
    
            }
    
    
            img{
    
                max-width:100%;
                border:5px solid @color-palette-tile-var;
    
            }
    
        }

        .side-column-section{
            display:grid;
            grid-template-columns: 1fr;
            grid-template-areas: "lf" "rh";
            padding:50px 0;
            font-size:16px;
            .section-title{
                font-size:16px;
                letter-spacing:2px;
                color:@color-palette-dark-blue-var;
                text-transform:uppercase;
            }
            h3{
                margin-top:0;
                font-size:26px;
            }
            h4{
                margin-top:0;
                font-size:20px;
            }
            .left-column{
                grid-area:lf;
            }
            .right-column{
                grid-area:rh;
            }
        }

        .square-cta{
            background-color:@color-palette-dark-blue-var;
            width:350px;
            height:350px;
            margin-right:auto;
            text-align:center;
            display:flex;
            flex-flow:column nowrap;
            align-items: center;
            justify-content: center;
            color:white;
            text-decoration:none;
            padding:0 45px;
            position:relative;
            overflow:hidden;
            *{
                position:relative;
                z-index:3;
            }
            span{
                display:block;
                &.big{ font-size: 30px; margin-bottom: 40px; }
                &.small{ font-size: 18px }
            }
            &:before{
                content:"";
                position:absolute;
                top:-20%;
                left:-20%;
                width:250px;
                height:250px;
                border-radius: 100%;
                background-color:#356a8d;
            }
        }

        .burbs{
            display:flex;
            flex-flow:row wrap;
            justify-content: space-between;
            margin-top:40px;
            .burb{
                width:320px;
                h3{
                    font-size:18px;
                    color:@color-palette-dark-blue-var;
                    letter-spacing: 2px;
                }
            }
        }

        .long-search-cta{
            background-color: @color-palette-dark-blue-var;
            font-size:36px;
            text-align:center;
            font-weight:900;
            color:white;
            padding:50px;
            position: relative;
            overflow:hidden;
            *{
                z-index:3;
                position:relative;
            }
            &:before{
                content:"";
                z-index:2;
                position:absolute;
                bottom:-10%;
                right:-10%;
                width:250px;
                height:250px;
                border-radius: 100%;
                background-color:#356a8d;
            }
            form{
                display:flex;
                flex-flow:row nowrap;
                justify-content: space-around;
                background-color:white;
                padding:0;
                margin:0;

                input[type="submit"]{
                    width: 34%;
                    border-radius: 0;
                    border: 0;
                    background-color: @color-palette-orange-var;
                    color: white;
                    padding: 15px;
                }

                select{
                    width:33%;
                    padding:15px;
                }
            }
        }

    /* Responsive */
        @media screen and (min-width:320px){}
        @media screen and (min-width:375px){}
        @media screen and (min-width:425px){}
        @media screen and (min-width:768px){

            
            .side-column-section{
                display:grid;
                grid-template-columns: .7fr 1.3fr;
                grid-template-areas: "lf rh";
                padding:25px 0;
                font-size:18px;
                .section-title{
                    font-size:18px;
                    letter-spacing:2px;
                    color:@color-palette-dark-blue-var;
                    text-transform:uppercase;
                }
                h3{
                    margin-top:0;
                    font-size:36px;
                }
                h4{
                    margin-top:0;
                    font-size:24px;
                }
                .left-column{
                    grid-area:lf;
                }
                .right-column{
                    grid-area:rh;
                }
            }

            .author-box{
                max-width:.px-to-rem(570)[@rem];
            }

            .double-content{
                display: flex;
                flex-flow: row nowrap;

                .iframe-container{ width: 100%; }

                .content-1:not([class*="column-sys"]){
                    width:50%;
                }

                .content-2:not([class*="column-sys"]){
                    width:50%;
                }
                .content-1,.content-2{
                    padding:.px-to-rem(20)[@rem];
                    display:flex;
                    flex-flow:column nowrap;
                    & > *:not(img){
                        flex-grow:1;
                    }
                }

            }

            .small-title{
                font-size:.px-to-rem(32)[@rem];
            }
            
        }
        @media screen and (min-width:1120px){
            
            .master-container{
                width:1110px;
            }
        }
        @media screen and (min-width:1024px){

            .author-box{
                flex-flow: row nowrap;
                max-width: .px-to-rem(1260)[@rem];
                width:calc(100% - .px-to-rem(80)[@rem]);
                margin-top:.px-to-rem(80)[@rem];
                margin-bottom:.px-to-rem(40)[@rem];
                .content{
                    align-self: baseline;
                    font-size:.px-to-rem(18)[@rem];
                    h2{
                        margin:0;
                        font-size:.px-to-rem(34)[@rem];
                    }

                }

                img{
                    max-width:.px-to-rem(200)[@rem];
                    margin-right:.px-to-rem(30)[@rem];
                }



            }
            
            .global-cta{
                padding: 0 .px-to-rem(45)[@rem];
            }


        }
        @media screen and (min-width:1440px){}
        @media screen and (min-width:2560px){}