/*
 *
 *  Variables. 
 *
 */
    
    @non_webp_placeholder : "data:image/gif;base64,R0lGODlhAQABAPcAAAAAAAAAMwAAZgAAmQAAzAAA/wArAAArMwArZgArmQArzAAr/wBVAABVMwBVZgBVmQBVzABV/wCAAACAMwCAZgCAmQCAzACA/wCqAACqMwCqZgCqmQCqzACq/wDVAADVMwDVZgDVmQDVzADV/wD/AAD/MwD/ZgD/mQD/zAD//zMAADMAMzMAZjMAmTMAzDMA/zMrADMrMzMrZjMrmTMrzDMr/zNVADNVMzNVZjNVmTNVzDNV/zOAADOAMzOAZjOAmTOAzDOA/zOqADOqMzOqZjOqmTOqzDOq/zPVADPVMzPVZjPVmTPVzDPV/zP/ADP/MzP/ZjP/mTP/zDP//2YAAGYAM2YAZmYAmWYAzGYA/2YrAGYrM2YrZmYrmWYrzGYr/2ZVAGZVM2ZVZmZVmWZVzGZV/2aAAGaAM2aAZmaAmWaAzGaA/2aqAGaqM2aqZmaqmWaqzGaq/2bVAGbVM2bVZmbVmWbVzGbV/2b/AGb/M2b/Zmb/mWb/zGb//5kAAJkAM5kAZpkAmZkAzJkA/5krAJkrM5krZpkrmZkrzJkr/5lVAJlVM5lVZplVmZlVzJlV/5mAAJmAM5mAZpmAmZmAzJmA/5mqAJmqM5mqZpmqmZmqzJmq/5nVAJnVM5nVZpnVmZnVzJnV/5n/AJn/M5n/Zpn/mZn/zJn//8wAAMwAM8wAZswAmcwAzMwA/8wrAMwrM8wrZswrmcwrzMwr/8xVAMxVM8xVZsxVmcxVzMxV/8yAAMyAM8yAZsyAmcyAzMyA/8yqAMyqM8yqZsyqmcyqzMyq/8zVAMzVM8zVZszVmczVzMzV/8z/AMz/M8z/Zsz/mcz/zMz///8AAP8AM/8AZv8Amf8AzP8A//8rAP8rM/8rZv8rmf8rzP8r//9VAP9VM/9VZv9Vmf9VzP9V//+AAP+AM/+AZv+Amf+AzP+A//+qAP+qM/+qZv+qmf+qzP+q///VAP/VM//VZv/Vmf/VzP/V////AP//M///Zv//mf//zP///wAAAAAAAAAAAAAAACH5BAEAAPwALAAAAAABAAEAAAgEAPcFBAA7";
    @webp_placeholder : "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAgA0JaQAA3AA/vuUAAA=";

    [data-type="backgroundImage"]{
        background-image:url(@non_webp_placeholder);
    }

    .webp{
        [data-type="backgroundImage"]{
            background-image:url(@webp_placeholder);
        }
    }

    /* Fonts */

        /* Sizes */
            @global-font-size:16;

    /* Colors */
        @color-palette-orange: #F37741; //#F36F37;
        :root{  --color-palette-orange: @color-palette-orange;    }
        @color-palette-orange-var: var(--color-palette-orange);
        
        @color-palette-orange-light: #f8ab89;
        :root{  --color-palette-orange-light: @color-palette-orange-light;    }
        @color-palette-orange-light-var: var(--color-palette-orange-light);

        @color-palette-orange-pbutton: #F37037; //#ff7853;
        :root{  --color-palette-orange-pbutton: @color-palette-orange-pbutton;    }
        @color-palette-orange-pbutton-var: var(--color-palette-orange-pbutton);
        
        @color-palette-tile: #0ba993;
        :root{  --color-palette-tile: @color-palette-tile;    }
        @color-palette-tile-var: var(--color-palette-tile);

        @color-palette-teal: #03A892;
        :root{  --color-palette-teal: @color-palette-teal;    }
        @color-palette-teal-var: var(--color-palette-teal);
        
        @color-palette-purple: #423672;
        :root{  --color-palette-purple: @color-palette-purple;    }
        @color-palette-purple-var: var(--color-palette-purple);

        @color-palette-dark-blue: #034571;
        :root{  --color-palette-dark-blue: @color-palette-dark-blue;    }
        @color-palette-dark-blue-var: var(--color-palette-dark-blue);

        @color-palette-blue: #006dd0;
        :root{  --color-palette-blue: @color-palette-blue;    }
        @color-palette-blue-var: var(--color-palette-blue);
        
        @color-support-1: #333333;
        :root{  --color-support-1: @color-support-1;    }
        @color-support-1-var: var(--color-support-1);

        @color-support-2: #707070;
        :root{  --color-support-2: @color-support-2;    }
        @color-support-2-var: var(--color-support-2);
        
        @color-support-3: #F5F5F5;
        :root{  --color-support-3: @color-support-3;    }
        @color-support-3-var: var(--color-support-3);

        @color-support-4: #E4E4E4;
        :root{  --color-support-4: @color-support-4;    }
        @color-support-4-var: var(--color-support-4);
        
        @color-support-5: #767777;
        :root{  --color-support-5: @color-support-5;    }
        @color-support-5-var: var(--color-support-5);
        
        @color-support-6: #f5f8fa;
        :root{  --color-support-6: @color-support-6;    }
        @color-support-6-var: var(--color-support-6);

        @color-support-7: #6d6d6d;
        :root{  --color-support-7: @color-support-7;    }
        @color-support-7-var: var(--color-support-7);

        @color-support-8: #4b76a3;
        :root{  --color-support-8: @color-support-8;    }
        @color-support-8-var: var(--color-support-8);

        @color-support-9: #009fff;
        :root{  --color-support-9: @color-support-9;    }
        @color-support-9-var: var(--color-support-9);

        @color-support-10: #f5f1eb;
        :root{  --color-support-10: @color-support-10;    }
        @color-support-10-var: var(--color-support-10);

        @color-support-10: #f3f3f3;
        :root{  --color-support-10: @color-support-10;    }
        @color-support-10-var: var(--color-support-10);


        @master-header-hamburger-color: @color-palette-orange-var;

    /* Shadows */
        @master-header-box-shadow: 0 6px 6px fadeout(black,84%);

 /*
 *
 *  Functions
 *
 */

    /* Pixel To Rem */
        .px-to-rem(@x){
            @result : calc(@x / @global-font-size);
            @rem : calc(@result * 1rem);
            @px : calc(@x * 1px);
        }

    /* Square */
        .square(@x){
            width:.px-to-rem(@x)[@rem];
            height:.px-to-rem(@x)[@rem];
        }

    /* column system */

    .column-gen (@i) when (@i > 0) {
        
        @media screen and (min-width:1024px){
            .column-system-@{i} {
                width: ~"@{i}%";
            }
            
        }

        .column-gen(@i - 1);
    }